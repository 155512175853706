var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.exportShow
        ? _c(
            "div",
            { staticClass: "viewBill" },
            [
              _c(
                "div",
                { staticClass: "header-back" },
                [
                  _c(
                    "a-button",
                    { attrs: { prefix: "left" }, on: { click: _vm.back } },
                    [_vm._v("返回")]
                  ),
                  _c("div", { staticClass: "header-back-title" }, [
                    _vm._v(
                      _vm._s(_vm.billParam.month.split("-")[0]) +
                        "年 " +
                        _vm._s(_vm.billParam.month.split("-")[1]) +
                        "月 " +
                        _vm._s(_vm.billParam.companyName)
                    ),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "list" }, [
                _c(
                  "ul",
                  _vm._l(_vm.personsNum, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "personNum",
                        class: { active: _vm.active === index },
                        on: {
                          click: function ($event) {
                            _vm.active = index
                          },
                        },
                      },
                      [
                        _c("p", [_vm._v(_vm._s(item.value))]),
                        _c("p", [_vm._v(_vm._s(item.label))]),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  _vm._l(_vm.summaryMoney, function (item, index) {
                    return _c("li", { key: index, staticClass: "personNum1" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm._f("stateFormatNum")(item.value))),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(item.label) + "(" + _vm._s(item.unit) + ")"
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _c("Table", {
                ref: "table",
                attrs: { active: _vm.active, "bill-param": _vm.billParam },
                on: { exportBatchSupply: _vm.exportBatchSupply },
              }),
            ],
            1
          )
        : _c("ExcelImport", {
            attrs: {
              title: "导入补缴调差",
              "bill-param": _vm.billParam,
              "import-template": "exportBatchSupply",
            },
            on: { goBack: _vm.goBack },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }